// overrides
$font-size-base: 0.85rem;

$btn-white-space: nowrap;

$container-max-widths: (
    sm: 540px,
    md: 826px,
) !default;

$form-label-font-weight: 700;

$list-group-border-radius: 0;

$progress-border-radius: 0;

$carousel-indicator-active-bg: #0d6efd;
$carousel-indicator-width: 16px;
$carousel-indicator-height: 16px;
