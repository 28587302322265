.page-seeding {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    flex-direction: column;

    .form {
        width: 100%;
        border-bottom: 1px solid #ccc;
        padding: 16px;
        display: flex;
    }

    .data {
        display: flex;
        flex-grow: 1;
        position: relative;
    }

    .max-width-250 {
        max-width: 250px;
    }

    .max-width-300 {
        max-width: 300px;
    }

    .min-width-200 {
        min-width: 200px;
    }

    .min-width-250 {
        min-width: 250px;
    }

    .min-width-300 {
        min-width: 300px;
    }

    .min-width-350 {
        min-width: 350px;
    }

    .min-width-500 {
        min-width: 500px;
    }

    .min-width-550 {
        min-width: 550px;
    }

    div {
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: $light;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: $light;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $secondary;
            border: 1px solid $secondary;
            border-radius: 10px;
        }
    }
}
