
.btn-inline {
    display: inline-block;
    border: none;
    color: $blue;
    background-color: transparent;
    padding: 0;
    font-weight: bold;
    text-decoration: underline;

    &:focus,
    &:hover,
    &:active {
        outline: none;
        color: $purple;
    }
}
