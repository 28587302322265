.overflow-y-scroll {
    overflow-y: scroll;
}

.cursor-move {
    cursor: move !important;
}

.right-0 {
    right: 0;
}
