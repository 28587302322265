html,
body,
#root,
.pages {
    height: 100%;
    width: 100%;
    display: flex;
    margin: 0;
    padding: 0;
    position: relative;
}

div {
    position: relative;
}