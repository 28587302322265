// page trans
.trans {
    transition: all var(--animation-duration) cubic-bezier(0.675, -0.49, 0.235, 1.41);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100%;
    min-width: 100%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &--top {
        justify-content: flex-start;

        .trans {
            justify-content: flex-start;
        }
    }
}

.trans-enter {
    opacity: 0;
}

.trans-enter-active {
    opacity: 1;
}

.trans-exit {
    opacity: 1;
}

.trans-exit-active {
    opacity: 0;
}

// component trans
.comp-trans {
    transition: all var(--animation-duration) cubic-bezier(0.675, -0.49, 0.235, 1.41);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
}

.comp-trans-enter {
    opacity: 0;
}

.comp-trans-enter-active {
    opacity: 1;
}

.comp-trans-exit {
    opacity: 1;
}

.comp-trans-exit-active {
    opacity: 0;
}

// slide
.slide {
    overflow: hidden;
    transition: max-height var(--animation-duration) linear, opacity var(--animation-duration) linear;
    max-height: 1500px;
    opacity: 1;

    &.slide-up {
        opacity: 0;
        max-height: 0;
    }
}

// list items
.animation-item-enter {
    opacity: 0;
}

.animation-item-enter-active {
    opacity: 1;
    transition: opacity var(--animation-duration) ease-in;
}

.animation-item-exit {
    opacity: 1;
}

.animation-item-exit-active {
    opacity: 0;
    transition: opacity var(--animation-duration) ease-in;
}
