.sidebar {
    width: 56px;
    flex-shrink: 0;
    background: #252e37;
    display: flex;
    justify-content: center;
    padding-top: 8px;

    img {
        max-width: 100%;
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            a {
                color: #c3cacf;
                background-color: #4e585f;
                width: 44px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                margin-bottom: 8px;
                padding: 8px;
                text-decoration: none;

                &:hover,
                &:focus,
                &:active {
                    color: white;
                }
            }
        }
    }
}
